@import url("https://fonts.googleapis.com/css2?family=Kanit:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Assistant:wght@200;300;400&family=Kanit:wght@400;700&display=swap");
.bio {
  background-color: rgba(124, 124, 140, 0.75);
  color: #05052C;
  font-family: "Kanit", sans-serif;
  letter-spacing: 1px;
  margin-top: 7rem;
  width: 17rem;
  padding: 1rem;
  border-radius: 0.3125rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 1px 2px 30px rgba(232, 88, 81, 0.25), 1px 2px 30px rgba(232, 88, 81, 0.25);
  scroll-margin-top: 6.25rem;
}
.bio-img {
  height: 15rem;
  width: 15rem;
  border-radius: 50%;
  box-shadow: -1px -2px 30px rgba(232, 88, 81, 0.25), 1px 2px 30px rgba(232, 88, 81, 0.25);
  position: relative;
}
.bio-img-container {
  height: 15rem;
  width: 15rem;
  position: relative;
}
.bio-img-container::after {
  content: "";
  background-image: radial-gradient(rgba(232, 88, 81, 0.5), rgba(7, 169, 154, 0.5), rgba(238, 239, 194, 0.5));
  opacity: 0.4;
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  z-index: 3;
  top: 0;
  left: 0;
}
.bio-text {
  text-align: center;
  margin-top: 2rem;
  font-family: "Kanit", sans-serif;
  font-size: 1.5rem;
}

#about-hr {
  margin-top: 1rem;
  border: 1px solid rgba(5, 5, 44, 0.25);
  box-shadow: 1px 2px 5px rgba(5, 5, 44, 0.1), 1px 2px 5px rgba(5, 5, 44, 0.1);
}