@import url("https://fonts.googleapis.com/css2?family=Kanit:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Assistant:wght@200;300;400&family=Kanit:wght@400;700&display=swap");
.small-tunes {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  scroll-margin-top: 8rem;
  margin-top: 4rem;
}
.small-tunes .link_window {
  width: 16rem;
  border-radius: 0.3125rem 0.3125rem 0 0;
  overflow: hidden;
}
.small-tunes_link-container {
  background-color: white;
  width: 16rem;
  border-radius: 0.3125rem 0.3125rem 0 0;
  display: flex;
  transform: translateX(0);
  transition: transform 0.5s ease-in-out;
}
.small-tunes_link-container-link {
  background-color: #EEEFC2;
  color: #05052C;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  text-decoration: none;
  border-radius: 0.3125rem 0.3125rem 0 0;
  box-shadow: inset 0.0625rem 0.0625rem 0.625rem rgba(30, 30, 30, 0.8), inset 0.0625rem 0.0625rem 0.625rem rgba(30, 30, 30, 0.8);
}
.small-tunes_link-container-link-img {
  width: 14rem;
  height: 14rem;
  position: relative;
}
.small-tunes_link-container-link-img.new-release::after {
  content: "";
  height: inherit;
  width: inherit;
  background: url("/public/assets/images/newReleaseBanner.svg");
  background-repeat: no-repeat;
  position: absolute;
  right: -1.5rem;
}
.small-tunes_link-container-link-img img {
  width: inherit;
  height: inherit;
  position: absolute;
}
.small-tunes_link-container-link-img-desc {
  color: #05052C;
  height: 9rem;
  width: 14rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  font-family: "Assistant", sans-serif;
  font-size: 1.1rem;
  text-shadow: 0.0313rem 0.0313rem rgba(30, 30, 30, 0.5);
  pointer-events: none;
  transition: all 0.4s ease-in-out;
}
.small-tunes_link-container-link-text {
  color: #05052C;
  font-size: 1.5rem;
  font-family: "Kanit", sans-serif;
  margin-top: 1rem;
  max-height: 10%;
  text-transform: uppercase;
  letter-spacing: 0.1875rem;
  animation: 3s textColorShift linear infinite;
}
.small-tunes_buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 3rem;
  width: 100%;
  margin-top: 0.5rem;
}
.small-tunes_buttons.paused {
  justify-content: center;
  align-items: center;
}
.small-tunes_buttons #prev,
.small-tunes_buttons #next {
  width: 39%;
  border-radius: 0 0 0 0.625rem;
}
.small-tunes_buttons #prev.hide,
.small-tunes_buttons #next.hide {
  display: none;
}
.small-tunes_buttons #next {
  border-radius: 0 0 0.625rem 0;
}
.small-tunes_buttons-btn {
  background-color: #EEEFC2;
  color: #05052C;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  font-family: "Kanit", sans-serif;
  font-size: 1.5rem;
  text-transform: uppercase;
  transform-origin: center;
  transition: all 0.4s ease-in-out;
  box-shadow: inset 0.0625rem 0.0625rem 0.625rem rgba(30, 30, 30, 0.3), inset 0.0625rem 0.0625rem 0.625rem rgba(30, 30, 30, 0.3);
  cursor: pointer;
}
.small-tunes_buttons-btn.pause {
  width: 19%;
}
.small-tunes_buttons-btn.pause img {
  width: 2rem;
  height: 2rem;
}
.small-tunes_buttons-btn.pause.long {
  width: 16rem;
  border-radius: 0 0 0.3125rem 0.3125rem;
}

@keyframes textColorShift {
  0% {
    color: #05052C;
  }
  50% {
    color: rgba(5, 5, 44, 0.5);
  }
  100% {
    color: #05052C;
  }
}