@import url("https://fonts.googleapis.com/css2?family=Kanit:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Assistant:wght@200;300;400&family=Kanit:wght@400;700&display=swap");
.player_container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 85vw;
  max-width: 30rem;
  margin: 14vh auto 0 auto;
}

.player {
  width: 100%;
  border-radius: 0.625rem;
  padding: 1rem;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.5), 2px 2px 10px rgba(0, 0, 0, 0.5);
}
.player_cover {
  width: 76vw;
  height: 76vw;
  max-width: 28rem;
  max-height: 28rem;
  position: relative;
}
.player_cover img {
  position: absolute;
  height: 100%;
  width: 100%;
}
.player_transport {
  margin: 1rem 0;
  width: 100%;
  height: 4rem;
  display: flex;
}
.player_transport_play, .player_transport_pause {
  width: 17%;
  cursor: pointer;
}
.player_transport_play :first-child, .player_transport_pause :first-child {
  width: 4rem;
  height: 4rem;
}
.player_transport_status {
  width: 61%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}
.player_transport_status-slider {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 5;
  opacity: 0;
  cursor: pointer;
}
.player_transport_status-bg {
  width: 100%;
  height: 0.85rem;
  border-radius: 10px;
  position: absolute;
  left: 0;
}
.player_transport_status-bar {
  width: 0;
  height: 0.85rem;
  border-radius: 10px;
  position: absolute;
  left: 0;
}
.player_transport_time {
  width: 22%;
  font-family: "Kanit", sans-serif;
  font-size: 1.25rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.release {
  font-family: "Kanit", sans-serif;
  margin: 1rem auto -3rem auto;
  width: 100%;
  border-radius: 0.625rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.release_available {
  font-size: 1.35rem;
  text-transform: uppercase;
  letter-spacing: 0.2rem;
}
.release_date {
  font-size: 1.5rem;
}

.description {
  display: flex;
  width: 85vw;
  max-width: 30rem;
  font-family: "Assistant", sans-serif;
  font-size: 1.25rem;
  margin: 4rem auto 0 auto;
  padding: 1rem;
  border-radius: 0.625rem;
  text-shadow: 1px 1px rgba(0, 0, 0, 0.3);
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.5), 2px 2px 10px rgba(0, 0, 0, 0.5);
}

@media (max-width: 907px) {
  .player_transport {
    height: 3.5rem;
  }
  .player_transport_play, .player_transport_pause {
    width: 20%;
  }
  .player_transport_play :first-child, .player_transport_pause :first-child {
    width: 3.5rem;
    height: 3.5rem;
  }
  .player_transport_status {
    width: 54%;
  }
  .player_transport_time {
    width: 26%;
    margin-left: 0.5rem;
    font-size: 1.15rem;
  }
}