.headline {
  margin-top: 3rem;
  scroll-margin-top: 5rem;
}
.headline_svg {
  max-width: 62.5rem;
  width: 90vw;
}

@media (max-width: 1300px) {
  .headline_svg {
    margin-top: 7vh;
  }
}
@media (max-width: 908px) {
  .headline_svg {
    margin-top: 3vh;
  }
}