@import url("https://fonts.googleapis.com/css2?family=Kanit:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Assistant:wght@200;300;400&family=Kanit:wght@400;700&display=swap");
.navbar {
  position: fixed;
  top: 0px;
  left: 0px;
  height: 3.75rem;
  width: 100vw;
  max-width: 100vw;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  z-index: 1000;
}
.navbar_logo {
  margin: 3.75rem 0 0 6vh;
  height: 7.5rem;
  width: 7.5rem;
}
.navbar_logo_svg {
  height: inherit;
  width: inherit;
}
.navbar_links {
  display: flex;
  flex-direction: row;
  padding-right: 1.25rem;
}
.navbar_links-link {
  list-style: none;
  padding: 0rem 0.75rem;
  transition: transform 0.3s ease-in-out;
}
.navbar_links-link:hover {
  transform: scale(1.2);
}
.navbar_links-link a {
  font-family: "Assistant", sans-serif;
  font-size: 1.5rem;
  font-weight: 600;
  text-decoration: none;
  letter-spacing: 0.07rem;
  -webkit-text-stroke: 1px;
  -webkit-text-stroke-color: rgba(29, 28, 26, 0.1);
  color: inherit;
}
.navbar .navbar_small {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}
.navbar .navbar_small .burger {
  width: 3.25rem;
  height: 70%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-right: 0.5rem;
  z-index: 1000;
  cursor: pointer;
  transition: transform 0.3s linear;
}
.navbar .navbar_small .burger-bar {
  background-color: #05052C;
  height: 20%;
  width: 100%;
  border-radius: 5px;
  transition: all 0.3s linear;
}
.navbar .navbar_small .burger.open {
  transform: translateX(40%);
}
.navbar .navbar_small .burger.open div:first-of-type {
  width: 105%;
  transform: rotate(53deg) translateY(1.75rem);
}
.navbar .navbar_small .burger.open div:nth-of-type(2) {
  width: 0;
}
.navbar .navbar_small .burger.open div:last-of-type {
  width: 105%;
  transform: rotate(-53deg) translateY(-1.75rem);
}
.navbar .navbar_small .menu {
  background-color: #7C7C8C;
  position: fixed;
  top: -12.5rem;
  right: 0;
  border-radius: 50%;
  height: 28.125rem;
  width: 28.125rem;
  z-index: 500;
  transform-origin: 25rem 4rem;
  transition: transform 0.4s linear, visibility 0.8s linear;
}
.navbar .navbar_small .menu_links {
  position: relative;
}
.navbar .navbar_small .menu_links_container {
  position: absolute;
  height: 15rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-evenly;
  top: 4rem;
  left: 1rem;
  transform: rotate(45deg);
}
.navbar .navbar_small .menu_links_container-link {
  color: #EEEFC2;
  text-decoration: none;
  font-family: "Assistant", sans-serif;
  font-size: 2rem;
  font-weight: bold;
}
.navbar .navbar_small .menu_links_container-link.active-link {
  color: #05052C;
}
.navbar .navbar_small .menu.open {
  transform: rotate(-45deg);
  visibility: visible;
}
.navbar .navbar_small .menu.closed {
  transform: rotate(65deg);
  visibility: hidden;
}
.navbar .navbar_small #music-link {
  transform: translateX(0.75rem);
}
.navbar .navbar_small #stream-link {
  transform: translateX(2.5rem);
}
.navbar .navbar_small #about-link {
  transform: translateX(6.25rem);
}

@media (max-width: 908px) {
  .navbar {
    height: 3.75rem;
    padding: 0 1rem;
  }
  .navbar_logo {
    margin: 3.75rem 0 0 2vh;
    height: 7.5rem;
    width: 7.5rem;
  }
  .navbar_logo_svg {
    height: 7.5rem;
    width: 7.5rem;
  }
}