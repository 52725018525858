@import url("https://fonts.googleapis.com/css2?family=Kanit:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Assistant:wght@200;300;400&family=Kanit:wght@400;700&display=swap");
.tunes {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-top: 7rem;
}
.tunes_container {
  width: 54rem;
  position: relative;
  scroll-margin-top: 6.25rem;
}
.tunes_pages {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 30rem;
  margin-top: 0.625rem;
  position: absolute;
  top: 21rem;
}
.tunes_pages-btn {
  background-color: #07A99A;
  color: #05052C;
  padding: 0.25rem 1rem;
  border: none;
  border-radius: 0.1875rem;
  box-shadow: 2px 1px 10px #EEEFC2;
  cursor: pointer;
}
.tunes_pages-btn:active {
  transform: scale(0.98);
}
.tunes_pages-btn.disabled {
  background-color: #7C7C8C;
  color: #ABA;
  cursor: not-allowed;
}
.tunes_pages-btn.disabled:active {
  transform: scale(1);
}
.tunes_pages-dot {
  background-color: #7C7C8C;
  height: 0.625rem;
  width: 0.625rem;
  border-radius: 50%;
  transition: all 0.5s ease-in-out;
}
.tunes_pages-dot.active {
  background-color: #E85851;
  transform: scale(1.1);
  animation: pump 0.5s linear;
}

@keyframes pump {
  0% {
    background-color: #E85851;
    scale: 1.1;
  }
  50% {
    background-color: #e46e68;
    scale: 1.25;
  }
  100% {
    background-color: #E85851;
    scale: 1.1;
  }
}