@import url("https://fonts.googleapis.com/css2?family=Kanit:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Assistant:wght@200;300;400&family=Kanit:wght@400;700&display=swap");
.stream {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.stream_links {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  position: relative;
}
.stream_links::before, .stream_links::after {
  content: "Stream this";
  position: absolute;
  color: #EEEFC2;
  text-transform: uppercase;
  font-size: 2rem;
  font-family: "Kanit", sans-serif;
  letter-spacing: 1rem;
  font-weight: bold;
  top: 0.4375rem;
  left: 50%;
  transform: translateX(-50%);
  animation: streamThem 10s ease-in-out infinite;
  opacity: 0;
  pointer-events: none;
  z-index: 10;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.stream_links::after {
  content: "Click this";
  animation: clickThem 10s ease-in-out infinite;
}
.stream_links-link {
  margin: 0.5rem 1rem;
  text-decoration: none;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.stream_links-link-svg {
  width: 3rem;
  height: 3rem;
  cursor: pointer;
  transition: transform 0.2s ease;
}
.stream_links-link-svg:hover {
  transform: scale(1.15);
}
.stream_links-link-label {
  color: #E85851;
  text-transform: lowercase;
  margin-top: 0.2rem;
  font-size: 1rem;
}
.stream_text {
  font-family: "Kanit", sans-serif;
  text-transform: uppercase;
  letter-spacing: 0.1875rem;
  margin-top: 0.5rem;
}

@keyframes streamThem {
  0% {
    opacity: 0.75;
  }
  17% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  82% {
    opacity: 0;
  }
  100% {
    opacity: 0.75;
  }
}
@keyframes clickThem {
  0% {
    opacity: 0;
  }
  32% {
    opacity: 0;
  }
  50% {
    opacity: 0.75;
  }
  67% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
@media (max-width: 852px) {
  .stream {
    width: 24rem;
  }
  .stream_links {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
  }
  .stream_links::before, .stream_links::after {
    width: 80%;
  }
}