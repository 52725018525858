* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

body {
  background-color: #05052C;
  height: auto;
  margin: 0;
  padding-bottom: 30rem;
}

.main {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 8vh;
  position: relative;
}