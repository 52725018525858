@import url("https://fonts.googleapis.com/css2?family=Kanit:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Assistant:wght@200;300;400&family=Kanit:wght@400;700&display=swap");
.television {
  background-color: #000;
  position: relative;
  border-radius: 0.625rem;
  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.7), 1px 1px 10px rgba(0, 0, 0, 0.7);
}
.television_black-border {
  background-color: transparent;
  border: solid 2rem black;
  border-radius: 0.625rem;
  width: 100%;
  height: 100%;
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none;
}
.television_face {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  pointer-events: none;
}
.television_face img {
  width: 100%;
}
.television_speaker {
  position: absolute;
  z-index: 100;
}
.television_speaker img {
  width: 100%;
}
.television_speaker.playing {
  animation: sound-on 1.5s infinite linear;
}
.television .youtube_embed {
  position: absolute;
  border: none;
  border-radius: 10px;
  opacity: 1;
  transform: scale(1);
  transition: all 1s ease-in;
}
.television .youtube_embed.loading {
  opacity: 0;
  transform: scale(0);
}
.television .youtube_embed::after {
  content: "II";
  background: conic-gradient(#FFFDD0, #999, #FFFDD0);
  font-family: "Assistant", sans-serif;
  font-weight: bold;
  font-size: 3rem;
  color: black;
  letter-spacing: 0.25rem;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 3.125rem;
  height: 3.125rem;
  border-radius: 50%;
  padding: 2rem;
  position: absolute;
  bottom: -3.5vw;
  left: 0;
  opacity: 0;
  pointer-events: none;
}
.television .youtube_embed.playing::after {
  animation: pause-overlay infinite 7s ease-in-out;
  opacity: 0.4;
}
.television_glass {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0.3;
  z-index: 3;
  pointer-events: none;
}
.television_glass img {
  width: 100%;
}

.showcase {
  margin: auto;
  margin-top: 15vh;
  width: 80vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.showcase_main {
  padding: 1rem;
  max-width: 45rem;
  max-height: 22.5rem;
  width: 80vw;
  height: 40vw;
  box-shadow: inset 0.0625rem 0.0625rem 0.625rem rgba(0, 0, 0, 0.3), inset 0.0625rem 0.0625rem 0.625rem rgba(0, 0, 0, 0.3);
  border-radius: 0.625rem;
  margin: 5rem 0 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.showcase_main_img {
  max-width: 20.25rem;
  width: 36vw;
  box-shadow: 0.0625rem 0.0625rem 0.625rem rgba(0, 0, 0, 0.2), 0.0625rem 0.0625rem 0.625rem rgba(0, 0, 0, 0.2);
  border-radius: 0.3125rem;
}
.showcase_main_desc {
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
.showcase_main_desc p {
  font-size: 1.5rem;
  font-family: "Assistant", sans-serif;
  letter-spacing: 0.05rem;
  text-shadow: 0.05rem 0.05rem rgba(55, 55, 55, 0.4), 0.05rem 0.05rem rgba(55, 55, 55, 0.4);
  margin: 1rem 0;
}
.showcase_main_desc hr {
  width: 100%;
  height: 0.0625rem;
  border: none;
  margin-bottom: 0.5rem;
}
.showcase_main_desc-date {
  font-family: "Assistant", sans-serif;
  font-size: 1.25rem;
}

@keyframes sound-on {
  0% {
    transform: rotate(0deg) scale(1.05);
  }
  25% {
    transform: rotate(1.5deg) scale(1);
  }
  50% {
    transform: rotate(0deg) scale(1.05);
  }
  75% {
    transform: rotate(-1.5deg) scale(1);
  }
  100% {
    transform: rotate(0deg) scale(1.05);
  }
}
@keyframes pause-overlay {
  0% {
    opacity: 0;
  }
  25% {
    opacity: 0.5;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
@media (max-width: 750px) {
  .showcase_main {
    flex-direction: column;
    max-height: 100%;
    height: auto;
  }
  .showcase_main img {
    max-width: 100%;
    width: 100%;
  }
  .showcase_main_desc {
    width: 100%;
  }
  .showcase_main_desc p {
    font-size: 1.15rem;
  }
  .showcase .youtube_embed::after {
    font-size: 2.5rem;
    letter-spacing: 0.25rem;
    width: 2.1875rem;
    height: 2.1875rem;
    padding: 1.5rem;
    bottom: -4vw;
    left: 1vw;
  }
}
@media (min-width: 1400px) {
  .showcase {
    margin-top: 10vh;
    width: 90vw;
    display: grid;
    grid-gap: 3rem;
    grid-template-columns: 3fr, 2fr;
    grid-template-rows: 2fr, 1fr;
  }
  .showcase_main {
    flex-direction: column;
    max-height: 580px;
    width: 400px;
    height: 580px;
    transform: translateY(1rem);
    grid-column: 2;
    grid-row: 1;
  }
  .showcase_main img {
    max-width: 100%;
    width: 100%;
  }
  .showcase_main_desc {
    width: 100%;
  }
  .showcase_main_desc p {
    font-size: 1.25rem;
  }

  .television {
    grid-column: 1;
    grid-row: 1;
  }
  .television .youtube_embed::after {
    bottom: -2vw;
    left: 0;
  }

  .stream.in-grid {
    grid-column: 1/2;
    grid-row: 2;
    margin-top: 0;
    transform: translateY(-12rem);
  }
}