@import url("https://fonts.googleapis.com/css2?family=Kanit:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Assistant:wght@200;300;400&family=Kanit:wght@400;700&display=swap");
.tunes-link {
  background-color: #EEEFC2;
  color: #05052C;
  width: 17rem;
  padding: 1rem;
  margin: 0 0.5rem;
  border-radius: 0.3125rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  position: absolute;
  animation: grow 1s;
  transition: left 0.5s ease-in-out, opacity 0.5s ease-in-out;
}
.tunes-link.first {
  top: 0;
  left: 0;
}
.tunes-link.second {
  top: 0;
  left: 18rem;
  z-index: 3;
}
.tunes-link.third {
  top: 0;
  left: 36rem;
}
.tunes-link-img {
  width: 15rem;
  height: 15rem;
  position: relative;
}
.tunes-link-img.new-release::after {
  content: "";
  height: inherit;
  width: inherit;
  background: url("/public/assets/images/newReleaseBanner.svg");
  background-repeat: no-repeat;
  position: absolute;
  right: -2.5rem;
}
.tunes-link-img img {
  width: inherit;
  height: inherit;
  position: absolute;
}
.tunes-link-img-desc {
  position: absolute;
  background-color: #EEEFC2;
  color: #05052C;
  z-index: 2;
  width: 100%;
  bottom: 0;
  padding: 0.3125rem;
  font-family: "Assistant", sans-serif;
  font-size: 0.85rem;
  border-radius: 10px 10px 0 0;
  pointer-events: none;
  transition: all 0.4s ease-in-out;
}
.tunes-link-img-desc.hide {
  opacity: 0;
}
.tunes-link-text {
  color: #05052C;
  font-size: 1.5rem;
  font-family: "Kanit", sans-serif;
  margin-top: 1rem;
  max-height: 10%;
  text-transform: uppercase;
  letter-spacing: 0.1875rem;
}
.tunes-link::before {
  content: "";
  background-color: rgba(0, 0, 0, 0.8);
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  animation: fadeIn 2s;
  z-index: 100;
}
.tunes-link.hidden {
  display: none;
}
.tunes-link.moveLeft {
  left: -18rem;
  opacity: 0;
}
.tunes-link.moveRight {
  left: 54rem;
  opacity: 0;
}

@keyframes grow {
  0% {
    scale: 0;
    opacity: 0;
  }
  100% {
    scale: 1;
    opacity: 1;
  }
}
@keyframes fadeIn {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}